import { appendUrlParam } from './append-url-param';
import type { BaseDataTableUrlParamsModel } from '../common/models/base/base-data-table-url-params.model';

export default function <T>(url: string, options: BaseDataTableUrlParamsModel<T>): string {
  if (options?.search) {
    url = appendUrlParam(url, 'search', options.search);
  }
  if (options?.sortBy && options?.sortOrder) {
    url = appendUrlParam(url, 'sortBy', options.sortBy);
    const sortOrder = naiveOrderToApi(options.sortOrder);
    url = appendUrlParam(url, 'sortOrder', sortOrder);
  }
  if (options?.skip) {
    url = appendUrlParam(url, 'skip', options.skip.toString());
  }
  if (options?.take) {
    url = appendUrlParam(url, 'take', options.take.toString());
  }

  return url;
}
